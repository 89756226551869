import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import {
  ContributorCategory,
  CustomContributorsApi,
} from 'cacao-sdk-typescript';
import axiosInstance from '../../utils/axiosConfig';

interface ContributorCategoriesResponse {
  contributorCategories: ContributorCategory[];
}

const fetchContributorCategories = async () => {
  try {
    const contributorsApi = new CustomContributorsApi(axiosInstance);
    const { data } =
      await contributorsApi.contributorCategoryGetAllCategoryGet();

    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching contributor categories:', error);
    throw new Error('Failed to fetch contributor categories');
  }
};

export const useContributorCategories = () => {
  return useQuery<ContributorCategoriesResponse>({
    queryKey: ['contributorCategories'],
    queryFn: fetchContributorCategories,
  });
};

import { FC, useState } from 'react';
import { Table, Button } from 'flowbite-react';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { Location } from 'cacao-sdk-typescript';
import { useTranslation } from 'react-i18next';
import EditLocationModal from './edit-location-modal';

const LocationsTable: FC<{ locations: Location[] }> = ({ locations }) => {
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );
  const [isEditOpen, setOpenEdit] = useState(false);
  const closeEditModel = () => setOpenEdit(false);
  const { t } = useTranslation();

  return (
    <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
      <Table.Head className="bg-gray-100 dark:bg-gray-700">
        <Table.HeadCell className="whitespace-nowrap">
          {t('locations.name')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('locations.slug')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('locations.address')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('locations.city')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('locations.postalCode')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('locations.locationType')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('locations.public')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('products.actions')}
        </Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
        {locations.length === 0 ? (
          <Table.Row>
            <Table.Cell
              colSpan={6}
              className="text-center p-4 text-base font-medium text-gray-500 dark:text-gray-400"
            >
              {t('locations.noResults')}
            </Table.Cell>
          </Table.Row>
        ) : (
          locations.map((location) => (
            <Table.Row
              key={location.id}
              className="hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <Table.Cell className="whitespace-nowrap p-4 text-base font-semibold text-gray-900 dark:text-white">
                {location.name}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                {location.slug}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                {location.address}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                {location.city}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                {location.postalCode}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                {location.locationType}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                {location.public ? 'True' : 'False'}
              </Table.Cell>
              <Table.Cell>
                <div className="flex items-center gap-x-3 whitespace-nowrap">
                  <Button
                    color="primary"
                    onClick={() => {
                      setSelectedLocation(location);
                      setOpenEdit(true);
                    }}
                  >
                    <div className="flex items-center gap-x-2">
                      <HiOutlinePencilAlt className="text-lg" />
                      {t('locations.edit')}
                    </div>
                  </Button>
                </div>
                {isEditOpen && selectedLocation && (
                  <EditLocationModal
                    location={selectedLocation}
                    open={isEditOpen}
                    onClose={closeEditModel}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))
        )}
      </Table.Body>
    </Table>
  );
};

export default LocationsTable;

import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { CustomContributorsApi } from 'cacao-sdk-typescript';
import axiosInstance from '../../utils/axiosConfig';

const fetchAllContributors = async () => {
  try {
    const contributorsApi = new CustomContributorsApi(axiosInstance);
    const { data } = await contributorsApi.contributorsAllGet();

    return data.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching contributors:', error);
    throw new Error('Failed to fetch contributors');
  }
};

export const useAllContributor = () => {
  return useQuery({
    queryKey: ['allContributor'],
    queryFn: fetchAllContributors,
  });
};

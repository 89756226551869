import { useState, type FC } from 'react';
import { Routes, Route } from 'react-router';

import './i18n';
import './index.css';
import { setupYupI18n } from './configs/i18n';
import useRouteChange from './utils/analytics';
import FlowbiteWrapper from './components/flowbite-wrapper';
import { ROUTES } from './paths';
import { RouteInterface } from './interfaces/Route';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './providers/AuthProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';

setupYupI18n();

const App: FC = function () {
  useRouteChange();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 10 * (60 * 1000),
            refetchOnWindowFocus: false,
            retry: 0,
          },
        },
      })
  );

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Toaster position="top-right" />
        <Routes>
          <Route element={<FlowbiteWrapper />}>
            {ROUTES.map((route: RouteInterface) =>
              route.private ? (
                <Route
                  key={route.key}
                  path={route.route}
                  element={<PrivateRoute child={<route.component />} />}
                />
              ) : (
                <Route
                  key={route.key}
                  path={route.route}
                  element={<route.component />}
                />
              )
            )}
          </Route>
        </Routes>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default App;

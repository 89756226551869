import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { CreateProduct, CustomProductsApi } from 'cacao-sdk-typescript';

const addProduct = async (product: CreateProduct) => {
  try {
    const productsApi = new CustomProductsApi(axiosInstance);
    const { data } = await productsApi.productsPost(product);
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error creating product:', error);
    throw new Error((error as any).data.message ?? 'Failed to create product');
  }
};

export const useCreateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (product: CreateProduct) => addProduct(product),
    onSuccess: () => {
      queryClient.invalidateQueries(['products'] as any);
    },
    onError: (error) => {
      Sentry.captureException(error);
      console.error('Error during mutation:', error);
    },
  });
};

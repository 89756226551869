import { FC, useState } from 'react';
import { Table, Button } from 'flowbite-react';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import EditProductModal from './edit-product-modal';
import { Product } from 'cacao-sdk-typescript';

const ProductsTable: FC<{ products: Product[] }> = ({ products }) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [isEditOpen, setOpenEdit] = useState(false);
  const closeEditModel = () => setOpenEdit(false);
  const { t } = useTranslation();

  return (
    <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
      <Table.Head className="bg-gray-100 dark:bg-gray-700">
        <Table.HeadCell className="whitespace-nowrap">
          {t('products.name')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('products.slug')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('products.description')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('products.createdAt')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('products.updatedAt')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('products.actions')}
        </Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
        {products.length === 0 ? (
          <Table.Row>
            <Table.Cell
              colSpan={6}
              className="text-center p-4 text-base font-medium text-gray-500 dark:text-gray-400"
            >
              {t('products.noResults')}
            </Table.Cell>
          </Table.Row>
        ) : (
          products.map((product) => (
            <Table.Row
              key={product.id}
              className="hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <Table.Cell className="whitespace-nowrap p-4 text-base font-semibold text-gray-900 dark:text-white">
                {product.name}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                {product.slug}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                {product.description}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                {product.createdAt
                  ? new Date(product.createdAt).toLocaleDateString()
                  : 'N/A'}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                {product.updatedAt
                  ? new Date(product.updatedAt).toLocaleDateString()
                  : 'N/A'}
              </Table.Cell>
              <Table.Cell>
                <div className="flex items-center gap-x-3 whitespace-nowrap">
                  <Button
                    color="primary"
                    onClick={() => {
                      setSelectedProduct(product);
                      setOpenEdit(true);
                    }}
                  >
                    <div className="flex items-center gap-x-2">
                      <HiOutlinePencilAlt className="text-lg" />
                      {t('products.editProduct')}
                    </div>
                  </Button>
                  {isEditOpen && selectedProduct && (
                    <EditProductModal
                      open={isEditOpen}
                      onClose={closeEditModel}
                      product={selectedProduct}
                    />
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          ))
        )}
      </Table.Body>
    </Table>
  );
};

export default ProductsTable;

import React, { useState, KeyboardEvent, ChangeEvent } from 'react';
import { Button, TextInput } from 'flowbite-react';
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

interface TagInputProps {
  initialValue: string[];
  onChange: (tags: string[]) => void;
  placeholder?: string;
}

const TagInput: React.FC<TagInputProps> = ({
  placeholder,
  initialValue,
  onChange,
}) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState<string[]>(initialValue || []);
  const [inputValue, setInputValue] = useState<string>('');

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (inputValue.trim() && !tags.includes(inputValue.trim())) {
        const updatedTags = [...tags, inputValue.trim()];
        setTags(updatedTags);
        onChange(updatedTags);
        setInputValue('');
      }
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const removeTag = (indexToRemove: number) => {
    const updatedTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(updatedTags);
    onChange(updatedTags);
  };

  return (
    <div className="w-full max-w-md">
      <div className="rounded-md mb-2">
        <TextInput
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder ?? t('common.addTagPlaceholder')}
          className="border-none outline-none flex-grow bg-transparent p-1"
        />
        <div className="mt-2 flex items-center flex-wrap gap-1">
          {tags &&
            tags.map((tag, index) => (
              <Button key={index} size="xs" color="primary">
                <div className="flex items-center gap-x-3">
                  {tag}
                  <IoMdClose
                    className="text-xl"
                    onClick={() => removeTag(index)}
                  />
                </div>
              </Button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TagInput;

import { createContext, useContext, useState, ReactNode } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { LoginCredentials, UserInfo } from '../interfaces/userInterfaces';
import { useNavigate } from 'react-router';
import { DEFAULT } from '../paths';
import * as Sentry from '@sentry/react';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (data: LoginCredentials) => Promise<void>;
  logout: () => void;
  userInfo: UserInfo;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!localStorage.getItem('xsrfToken')
  );

  // Initialize userInfo from localStorage if available
  const [userInfo, setUserInfo] = useState<UserInfo>(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    return storedUserInfo
      ? JSON.parse(storedUserInfo)
      : { userFullName: '', email: '', role: '', userInitials: '' };
  });

  const login = async ({ email, password }: LoginCredentials) => {
    try {
      const response = await axiosInstance.post('/user/login', {
        email,
        password,
      });

      if (response && response.data && response.data.xsrfToken) {
        const { xsrfToken, refreshToken } = response.data;

        localStorage.setItem('xsrfToken', JSON.stringify(xsrfToken));
        localStorage.setItem('refreshToken', refreshToken);
        setIsAuthenticated(true);
        navigate(DEFAULT);
        await getUserProfile();
      } else {
        throw response;
      }
    } catch (error) {
      console.error('Login error:', error);
      Sentry.captureException(error);
      // Throw the error so it can be caught by the onSubmit function
      throw error;
    }
  };

  const logout = async () => {
    try {
      const response = await axiosInstance.post('/user/logout');

      if (response && response.status === 200) {
        // Delete data of localStorage
        localStorage.removeItem('xsrfToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userInfo');

        // Update auth state
        setIsAuthenticated(false);
      } else {
        throw new Error('Logout failed');
      }
    } catch (error) {
      console.error('Logout error:', error);
      Sentry.captureException(error);
    }
  };

  const getUserProfile = async () => {
    try {
      const response = await axiosInstance.get('user/profile', {
        withCredentials: true,
      });
      const userInfo: UserInfo = {
        userFullName: response.data.userFullName,
        email: response.data.email,
        role: response.data.role,
        userInitials: response.data.userInitials,
      };
      setUserInfo(userInfo);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, userInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

import classNames from 'classnames';
import { Dropdown, Sidebar, TextInput, Tooltip } from 'flowbite-react';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import {
  HiAdjustments,
  HiChartPie,
  HiChartSquareBar,
  HiClipboard,
  HiCog,
  HiCollection,
  HiInboxIn,
  HiInformationCircle,
  HiLockClosed,
  HiSearch,
  HiShoppingBag,
  HiUsers,
  HiViewGrid,
} from 'react-icons/hi';
import { MdAdminPanelSettings } from 'react-icons/md';
import { USFlagIcon, FRFlagIcon } from '../assets/icons';
import { useSidebarContext } from '../context/SidebarContext';
import isSmallScreen from '../helpers/is-small-screen';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LOCALE } from '../configs/constants';

const ExampleSidebar: FC = function () {
  const { isOpenOnSmallScreens: isSidebarOpenOnSmallScreens } =
    useSidebarContext();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState('');
  const [isEcommerceOpen, setEcommerceOpen] = useState(true);
  const [isAdminOpen, setAdminOpen] = useState(true);
  const [isUsersOpen, setUsersOpen] = useState(true);

  useEffect(() => {
    const newPage = window.location.pathname;

    setCurrentPage(newPage);
    setEcommerceOpen(newPage.includes('/business/'));
    setAdminOpen(newPage.includes('/admin/'));
    setUsersOpen(newPage.includes('/users/'));
  }, [setCurrentPage, setEcommerceOpen, setAdminOpen, setUsersOpen]);

  const isTemplateEnabled = import.meta.env.VITE_TEMPLATE_ENABLED === 'true';

  return (
    <div
      className={classNames('lg:!block', {
        hidden: !isSidebarOpenOnSmallScreens,
      })}
    >
      <Sidebar
        aria-label="Sidebar with multi-level dropdown example"
        collapsed={isSidebarOpenOnSmallScreens && !isSmallScreen()}
      >
        <div className="flex h-full flex-col justify-between py-2 bg-red-90">
          <div>
            {isTemplateEnabled && (
              <form className="pb-3 md:hidden">
                <TextInput
                  icon={HiSearch}
                  type="search"
                  placeholder="Search"
                  required
                  size={32}
                />
              </form>
            )}
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <Sidebar.Item
                  href="/"
                  icon={HiChartPie}
                  className={
                    '/' === currentPage ? 'bg-gray-100 dark:bg-gray-700' : ''
                  }
                >
                  {t('sidebar.dashboard')}
                </Sidebar.Item>
                {isTemplateEnabled && (
                  <Sidebar.Item
                    href="/kanban"
                    icon={HiViewGrid}
                    className={
                      '/kanban' === currentPage
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : ''
                    }
                  >
                    Kanban
                  </Sidebar.Item>
                )}
                {isTemplateEnabled && (
                  <Sidebar.Item
                    href="/mailing/inbox"
                    icon={HiInboxIn}
                    label="3"
                    className={
                      '/mailing/inbox' === currentPage
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : ''
                    }
                  >
                    Inbox
                  </Sidebar.Item>
                )}
                <Sidebar.Collapse
                  icon={HiShoppingBag}
                  label={t('sidebar.business')}
                  open={isEcommerceOpen}
                >
                  <Sidebar.Item
                    href="/business/informations"
                    className={
                      '/business/informations' === currentPage
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : ''
                    }
                  >
                    {t('sidebar.informations')}
                  </Sidebar.Item>
                  <Sidebar.Item
                    href="/business/locations"
                    className={
                      '/business/locations' === currentPage
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : ''
                    }
                  >
                    {t('sidebar.locations')}
                  </Sidebar.Item>
                  <Sidebar.Item
                    href="/business/products"
                    className={
                      '/business/products' === currentPage
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : ''
                    }
                  >
                    {t('sidebar.products')}
                  </Sidebar.Item>
                </Sidebar.Collapse>
                <Sidebar.Collapse
                  icon={MdAdminPanelSettings}
                  label={t('admin.title')}
                  open={isAdminOpen}
                >
                  <Sidebar.Item
                    href="/admin/contributors"
                    className={
                      '/admin/contributors' === currentPage
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : 'truncate'
                    }
                  >
                    {t('admin.contributors')}
                  </Sidebar.Item>
                  <Sidebar.Item
                    href="/admin/products"
                    className={
                      '/admin/products' === currentPage
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : ''
                    }
                  >
                    {t('admin.products')}
                  </Sidebar.Item>
                  <Sidebar.Item
                    href="/admin/locations"
                    className={
                      '/admin/locations' === currentPage
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : ''
                    }
                  >
                    {t('admin.locations')}
                  </Sidebar.Item>
                  {isTemplateEnabled && (
                    <Sidebar.Item
                      href="/business/billing"
                      className={
                        '/business/billing' === currentPage
                          ? 'bg-gray-100 dark:bg-gray-700'
                          : ''
                      }
                    >
                      Billing
                    </Sidebar.Item>
                  )}
                  {isTemplateEnabled && (
                    <Sidebar.Item
                      href="/business/invoice"
                      className={
                        '/business/invoice' === currentPage
                          ? 'bg-gray-100 dark:bg-gray-700'
                          : ''
                      }
                    >
                      Invoice
                    </Sidebar.Item>
                  )}
                </Sidebar.Collapse>
                {isTemplateEnabled && (
                  <Sidebar.Collapse
                    icon={HiUsers}
                    label="Users"
                    open={isUsersOpen}
                  >
                    <Sidebar.Item
                      href="/users/list"
                      className={
                        '/users/list' === currentPage
                          ? 'bg-gray-100 dark:bg-gray-700'
                          : ''
                      }
                    >
                      Users list
                    </Sidebar.Item>
                    <Sidebar.Item
                      href="/users/profile"
                      className={
                        '/users/profile' === currentPage
                          ? 'bg-gray-100 dark:bg-gray-700'
                          : ''
                      }
                    >
                      Profile
                    </Sidebar.Item>
                    <Sidebar.Item
                      href="/users/feed"
                      className={
                        '/users/feed' === currentPage
                          ? 'bg-gray-100 dark:bg-gray-700'
                          : ''
                      }
                    >
                      Feed
                    </Sidebar.Item>
                    <Sidebar.Item
                      href="/users/settings"
                      className={
                        '/users/settings' === currentPage
                          ? 'bg-gray-100 dark:bg-gray-700'
                          : ''
                      }
                    >
                      Settings
                    </Sidebar.Item>
                  </Sidebar.Collapse>
                )}
                {isTemplateEnabled && (
                  <Sidebar.Collapse icon={HiChartSquareBar} label="Pages">
                    <Sidebar.Item href="/pages/pricing">Pricing</Sidebar.Item>
                    <Sidebar.Item href="/pages/maintenance">
                      Maintenace
                    </Sidebar.Item>
                    <Sidebar.Item href="/pages/404">404 not found</Sidebar.Item>
                    <Sidebar.Item href="/pages/500">
                      500 server error
                    </Sidebar.Item>
                  </Sidebar.Collapse>
                )}
                {isTemplateEnabled && (
                  <Sidebar.Collapse icon={HiLockClosed} label="Authentication">
                    <Sidebar.Item href="/authentication/sign-in">
                      Sign in
                    </Sidebar.Item>
                    <Sidebar.Item href="/authentication/sign-up">
                      Sign up
                    </Sidebar.Item>
                    <Sidebar.Item href="/authentication/forgot-password">
                      Forgot password
                    </Sidebar.Item>
                    <Sidebar.Item href="/authentication/reset-password">
                      Reset password
                    </Sidebar.Item>
                    <Sidebar.Item href="/authentication/profile-lock">
                      Profile lock
                    </Sidebar.Item>
                  </Sidebar.Collapse>
                )}
              </Sidebar.ItemGroup>
              {isTemplateEnabled && (
                <Sidebar.ItemGroup>
                  <Sidebar.Item
                    href="https://github.com/themesberg/flowbite-react/"
                    icon={HiClipboard}
                  >
                    Docs
                  </Sidebar.Item>
                  <Sidebar.Item
                    href="https://flowbite-react.com/"
                    icon={HiCollection}
                  >
                    Components
                  </Sidebar.Item>
                  <Sidebar.Item
                    href="https://github.com/themesberg/flowbite-react/issues"
                    icon={HiInformationCircle}
                  >
                    Help
                  </Sidebar.Item>
                </Sidebar.ItemGroup>
              )}
            </Sidebar.Items>
          </div>
          <BottomMenu />
        </div>
      </Sidebar>
    </div>
  );
};

const BottomMenu: FC = function () {
  const isTemplateEnabled = import.meta.env.VITE_TEMPLATE_ENABLED === 'true';

  return (
    <div className="flex items-center justify-center gap-x-5">
      {isTemplateEnabled && (
        <button className="rounded-lg p-2 hover:bg-gray-100 dark:hover:bg-gray-700">
          <span className="sr-only">Tweaks</span>
          <HiAdjustments className="text-2xl text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white " />
        </button>
      )}
      {isTemplateEnabled && (
        <div>
          <Tooltip content="Settings page">
            <a
              href="/users/settings"
              className="inline-flex cursor-pointer justify-center rounded p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span className="sr-only">Settings page</span>
              <HiCog className="text-2xl text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" />
            </a>
          </Tooltip>
        </div>
      )}
      <div>
        <LanguageDropdown />
      </div>
    </div>
  );
};

interface LanguageOption {
  label: string;
  svg: JSX.Element;
}

const languages: Record<string, LanguageOption> = {
  en: {
    label: 'English (US)',
    svg: USFlagIcon,
  },
  fr: {
    label: 'Français',
    svg: FRFlagIcon,
  },
};

const LanguageDropdown: FC = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <Dropdown
      arrowIcon={false}
      dismissOnClick={true}
      inline
      label={
        <span className="inline-flex rounded-none cursor-pointer justify-center  p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white">
          <span className="sr-only">Current language</span>
          {(languages[i18n.language] ?? languages[DEFAULT_LOCALE])?.svg}
        </span>
      }
    >
      {Object.keys(languages).map((key, index) => (
        <Dropdown.Item
          key={key}
          onClick={() => handleLanguageChange(key)}
          className={`${index === 0 ? ' rounded-t-[10px]' : ''} ${
            index === Object.keys(languages).length - 1
              ? ' rounded-b-[10px]'
              : ''
          }`}
        >
          <div className="inline-flex items-center">
            {languages[key]?.svg || <div>No Flag</div>}
            <span className="whitespace-nowrap">
              {languages[key]?.label || 'No Label'}
            </span>
          </div>
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

export default ExampleSidebar;

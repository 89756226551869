import { SocialMediaLinks } from '../interfaces/location';

export const DEFAULT_LOCALE = 'fr';

export const validLanguages = ['en', 'fr'];

export const supportedLocationSocialMedias: (keyof SocialMediaLinks)[] = [
  'facebook',
  'instagram',
  'linkedin',
  'twitter',
];

import { useState, FC, useEffect } from 'react';
import NavbarSidebarLayout from '../../layouts/navbar-sidebar';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Button, TextInput } from 'flowbite-react';
import { HiHome, HiPlus } from 'react-icons/hi';
import AddContributorModal from '../../components/Contributors/add-contributor-modal';
import Pagination from '../../components/pagination';
import Loader from '../../components/loader';
import ContributorsTable from '../../components/Contributors/contributors-table';
import { useContributors } from '../../hooks/api/useContributors';
import { DEFAULT_PAGE_SIZE } from '../../utils/const';
import toast from 'react-hot-toast';

const ContributorsPage: FC = () => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(DEFAULT_PAGE_SIZE);

  const { t } = useTranslation();

  const { data, isLoading, isError } = useContributors({
    page,
    pageSize,
    searchText: searchQuery,
  });

  const { data: contributors = [], metadata } = data || {};
  const { totalResults } = metadata || {};

  const closeAddModel = () => setIsAddOpen(false);

  // Display a toast if there's an error
  useEffect(() => {
    if (isError) {
      toast.error(t('contributors.errorLoading'), {
        duration: 4000,
        position: 'top-right',
      });
    }
  }, [isError, t]);

  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">{t('sidebar.home')}</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/admin/contributors">
                {t('admin.title')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('admin.contributors')}</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              {t('contributorTable.title')}
            </h1>
          </div>

          <div className="sm:flex">
            <form className="mb-4 sm:mb-0 sm:pr-3">
              <div className="relative mt-1 lg:w-64 xl:w-96">
                <TextInput
                  id="contributors-search"
                  name="contributors-search"
                  placeholder={t('contributorTable.searchPlaceholder')}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </form>
            <div className="ml-auto flex items-center space-x-2 sm:space-x-3">
              <Button color="primary" onClick={() => setIsAddOpen(true)}>
                <div className="flex items-center gap-x-3">
                  <HiPlus className="text-xl" />
                  {t('contributorTable.addContributor')}
                </div>
              </Button>
              {isAddOpen && (
                <AddContributorModal open={isAddOpen} onClose={closeAddModel} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col  ">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              {isLoading ? (
                <Loader className="flex justify-center my-8" />
              ) : (
                <ContributorsTable contributors={contributors} />
              )}
            </div>
          </div>
        </div>
      </div>
      {totalResults && (
        <Pagination
          currentPage={page}
          totalCount={totalResults}
          pageSize={pageSize}
          onPageChange={setPage}
        />
      )}
    </NavbarSidebarLayout>
  );
};

export default ContributorsPage;

import { FC, useMemo } from 'react';
import {
  Button,
  Label,
  Modal,
  TextInput,
  Textarea,
  FileInput,
  Select,
} from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Product } from 'cacao-sdk-typescript';
import { useEditProduct } from '../../hooks/api/updateProduct'; // Mutation hook to update product
import { useAllContributor } from '../../hooks/api/useAllContributors'; // Hook to fetch contributors
import TagInput from '../tag-input';

export interface EditProductProps {
  open: boolean;
  onClose: () => void;
  product: Product;
}

interface FormValues {
  name: string;
  description: string;
  labels: string[];
  picture?: FileList;
  contributorId?: string;
}

const EditProductModal: FC<EditProductProps> = ({ open, onClose, product }) => {
  const { t } = useTranslation();
  const { mutate: editProduct } = useEditProduct();
  const { data: contributors, isLoading: contributorLoading } =
    useAllContributor();

  const defaultValues = useMemo(
    () => ({
      name: product.name,
      description: product.description || '',
      contributorId: product.contributor?.id || '',
      picture: undefined,
      labels: product.labels,
    }),
    [product]
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues,
  });

  const onSubmit = (data: FormValues) => {
    const picture = data.picture?.[0];

    editProduct(
      {
        productId: product.id,
        updatedData: {
          ...data,
          picture,
        },
      },
      {
        onSuccess: handleClose,
      }
    );
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal onClose={handleClose} size="4xl" show={open}>
      <Modal.Header>
        <strong>{t('productModal.editTitle')}</strong>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            {/* Name Field */}
            <div>
              <Label htmlFor="name">{t('productModal.name')}</Label>
              <TextInput
                id="name"
                {...register('name', { required: 'Product name is required' })}
              />
              {errors.name && (
                <p className="text-red-500">{errors.name.message}</p>
              )}
            </div>

            <div>
              <Label htmlFor="name">{t('productModal.slug')}</Label>
              <TextInput id="name" value={product.slug} disabled />
            </div>

            {/* Description Field */}
            <div>
              <Label htmlFor="description">
                {t('productModal.description')}
              </Label>
              <Textarea id="description" {...register('description')} />
            </div>

            {/* Contributor Select Field */}
            {!contributorLoading && (
              <div>
                <Label htmlFor="contributorId">
                  {t('productModal.contributor')}
                </Label>
                <Select id="contributorId" {...register('contributorId')}>
                  {(contributors as any).contributors?.map(
                    (contributor: any) => (
                      <option key={contributor.id} value={contributor.id}>
                        {contributor.tradeName}
                      </option>
                    )
                  )}
                </Select>
              </div>
            )}

            {/* Image Upload Field */}
            <div>
              <Label htmlFor="picture">{t('productModal.image')}</Label>
              <FileInput
                id="picture"
                accept="image/png, image/jpeg"
                {...register('picture')}
              />
              {errors.picture && (
                <p className="text-red-500">{errors.picture.message}</p>
              )}
            </div>

            {/* Labels Field */}
            <div>
              <Label htmlFor="labels">{t('productModal.labels')}</Label>
              <Controller
                name="labels"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TagInput
                    placeholder={t('productModal.addLabelPlaceholder')}
                    initialValue={value || []}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">{t('productModal.saveButton')}</Button>
          <Button color="gray" onClick={handleClose}>
            {t('productModal.cancel')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditProductModal;

import { FC, useMemo } from 'react';
import { format } from 'date-fns';
import {
  Button,
  Label,
  Modal,
  TextInput,
  Textarea,
  Select,
  Checkbox,
  Datepicker,
} from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Location, LocationType } from 'cacao-sdk-typescript';
import {
  ExceptionalClosure,
  IDates,
  OpeningHours,
  SocialMediaLinks,
} from '../../interfaces/location';
import { useEditLocation } from '../../hooks/api/updateLocation';
import DateRangePicker from '../common/date-range-picker';
import TagInput from '../tag-input';
import ObjectInput from '../object-input';
import { supportedLocationSocialMedias } from '../../configs/constants';

interface EditLocationModalProps {
  open: boolean;
  onClose: () => void;
  location: Location;
}

export interface FormValues {
  name: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  latitude: number;
  longitude: number;
  googleMapsLink: string;
  locationType: LocationType;
  openingHours: OpeningHours;
  consultableDates: IDates[];
  openingDates: IDates[];
  contactPhone: string;
  contactEmail: string;
  website: string;
  socialMediaLinks: SocialMediaLinks;
  tags: string[];
  public: boolean;
  description: string;
  exceptionalClosures: ExceptionalClosure[];
}

const EditLocationModal: FC<EditLocationModalProps> = ({
  open,
  onClose,
  location,
}) => {
  const { t } = useTranslation();
  const { mutate: editLocation } = useEditLocation();

  const defaultValues = useMemo(
    () => ({
      name: location.name,
      address: location.address,
      city: location.city,
      postalCode: location.postalCode,
      country: location.country,
      latitude: location.latitude,
      longitude: location.longitude,
      googleMapsLink: location.googleMapsLink,
      locationType: location.locationType,
      openingHours: location.openingHours || {
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
        sunday: '',
      },
      consultableDates: location.consultableDates,
      openingDates: location.openingDates,
      contactPhone: location.contactPhone,
      contactEmail: location.contactEmail,
      website: location.website,
      socialMediaLinks: location.socialMediaLinks || {
        facebook: '',
        instagram: '',
        linkedin: '',
        twitter: '',
      },
      tags: location.tags || [],
      public: location.public,
      description: location.description,
      exceptionalClosures: location.exceptionalClosures,
    }),
    [location]
  );

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
  });

  const onSubmit = (data: FormValues) => {
    editLocation(
      {
        locationId: location.id as string,
        updatedData: data,
      },
      {
        onSuccess: () => {
          onClose();
        },
        onError: (error: any) => {
          console.error('Failed to update location:', error);
        },
      }
    );
  };

  return (
    <Modal onClose={onClose} show={open} size="4xl">
      <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
        <strong>{t('locationModal.editTitle')}</strong>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            <div>
              <Label>Slug</Label>
              <TextInput
                className="mt-1"
                type="text"
                value={location.slug}
                disabled={true}
              />
            </div>
            {/* Form Fields */}
            {[
              { label: t('locationModal.name'), id: 'name', type: 'text' },
              {
                label: t('locationModal.address'),
                id: 'address',
                type: 'text',
              },
              { label: t('locationModal.city'), id: 'city', type: 'text' },
              {
                label: t('locationModal.postalCode'),
                id: 'postalCode',
                type: 'text',
              },
              {
                label: t('locationModal.country'),
                id: 'country',
                type: 'text',
              },
              {
                label: t('locationModal.googleMapsLink'),
                id: 'googleMapsLink',
                type: 'text',
              },
              {
                label: t('locationModal.contactPhone'),
                id: 'contactPhone',
                type: 'text',
              },
              {
                label: t('locationModal.contactEmail'),
                id: 'contactEmail',
                type: 'email',
              },
              {
                label: t('locationModal.website'),
                id: 'website',
                type: 'text',
              },
              {
                label: t('locationModal.latitude'),
                id: 'latitude',
                type: 'text',
              },
              {
                label: t('locationModal.longitude'),
                id: 'longitude',
                type: 'text',
              },
              {
                label: t('locationModal.tags'),
                id: 'tags',
                type: 'custom',
              },
              {
                label: t('locationModal.description'),
                id: 'description',
                type: 'textarea',
              },
            ].map((field) => (
              <div key={field.id} className="col-span-2 md:col-span-1">
                <Label htmlFor={field.id}>{field.label}</Label>
                <div className="mt-1">
                  {field.type === 'textarea' ? (
                    <Textarea
                      id={field.id}
                      {...register(field.id as keyof FormValues)}
                    />
                  ) : field.type === 'custom' ? (
                    <TagInput
                      initialValue={location.tags || []}
                      onChange={(tags: string[]) => setValue('tags', tags)}
                    />
                  ) : (
                    <TextInput
                      id={field.id}
                      type={field.type}
                      {...register(field.id as keyof FormValues)}
                    />
                  )}
                  {errors[field.id as keyof FormValues] && (
                    <p className="text-red-500">
                      {errors[field.id as keyof FormValues]?.message}
                    </p>
                  )}
                </div>
              </div>
            ))}
            {/* Location Type */}
            <div className="col-span-2 md:col-span-1">
              <Label htmlFor="locationType">
                {t('locationModal.locationType')}
              </Label>
              <div className="mt-1">
                <Select id="locationType" {...register('locationType')}>
                  {Object.values(LocationType).map((type) => (
                    <option key={type} value={type}>
                      {t(`locationType.${type}`)}
                    </option>
                  ))}
                </Select>
                {errors.locationType && (
                  <p className="text-red-500">{errors.locationType?.message}</p>
                )}
              </div>
            </div>
            {/* Opening Hours */}
            <div className="col-span-2 md:col-span-1">
              <Label htmlFor="openingHours">
                {t('locationModal.openingHours')}
              </Label>
              <Controller
                control={control}
                name="openingHours"
                render={({ field: { onChange, value } }) => (
                  <ObjectInput
                    keys={[
                      'monday',
                      'tuesday',
                      'wednesday',
                      'thursday',
                      'friday',
                      'saturday',
                      'sunday',
                    ]}
                    value={value as any}
                    onChange={onChange}
                    placeholder={t('locationModal.openingHoursPlaceholder')}
                  />
                )}
              />
              {errors.openingHours && (
                <p className="text-red-500">{errors.openingHours.message}</p>
              )}
            </div>
            {/* Exceptional Closures */}
            <div className="col-span-2 md:col-span-1">
              <Label htmlFor="exceptionalClosures">
                {t('locationModal.exceptionalClosuresDate')}
              </Label>
              <Controller
                control={control}
                name="exceptionalClosures"
                render={({ field }) => (
                  <Datepicker
                    onSelectedDateChanged={(date) => {
                      field.onChange([
                        {
                          ...field.value?.[0],
                          date,
                        },
                      ]);
                    }}
                    value={
                      field.value?.[0]?.date
                        ? format(field.value?.[0]?.date, 'yyyy-MM-dd')
                        : undefined
                    }
                  />
                )}
              />
              {errors.exceptionalClosures?.[0]?.date && (
                <p className="text-red-500">
                  {errors.exceptionalClosures?.[0]?.date?.message}
                </p>
              )}
            </div>
            <div className="col-span-2 md:col-span-1">
              <Label htmlFor="exceptionalClosures">
                {t('locationModal.exceptionalClosuresReason')}
              </Label>
              <Controller
                control={control}
                name="exceptionalClosures"
                render={({ field }) => (
                  <TextInput
                    id=""
                    type="text"
                    value={field.value?.[0]?.reason || ''}
                    onChange={(e) => {
                      const reason = e.target.value;
                      field.onChange([
                        {
                          ...field.value?.[0],
                          reason,
                        },
                      ]);
                    }}
                  />
                )}
              />
              {errors.exceptionalClosures?.[0]?.reason && (
                <p className="text-red-500">
                  {errors.exceptionalClosures?.[0]?.reason?.message}
                </p>
              )}
            </div>
            {/* Consultable Dates */}
            <div className="col-span-2">
              <Label htmlFor="consultableDates">
                {t('locationModal.consultableDates')}
              </Label>
              <Controller
                control={control}
                name="consultableDates"
                render={({ field }) => (
                  <DateRangePicker
                    startDate={field.value?.[0]?.startDate}
                    endDate={field.value?.[0]?.endDate}
                    onChange={(startDate, endDate) => {
                      field.onChange([{ startDate, endDate }]);
                    }}
                  />
                )}
              />
              {errors.consultableDates && (
                <p className="text-red-500">
                  {errors.consultableDates?.message}
                </p>
              )}
            </div>
            <div className="col-span-2 md:col-span-1">
              <Label htmlFor="socialMediaLinks">
                {t('locationModal.socialMediaLinks')}
              </Label>
              <Controller
                control={control}
                name="socialMediaLinks"
                render={({ field: { onChange, value } }) => (
                  <ObjectInput
                    keys={supportedLocationSocialMedias}
                    value={value as any}
                    onChange={onChange}
                  />
                )}
              />
              {errors.socialMediaLinks && (
                <p className="text-red-500">
                  {errors.socialMediaLinks?.message}
                </p>
              )}
            </div>
            {/* Opening Dates */}
            <div className="col-span-2">
              <Label htmlFor="openingDates">
                {t('locationModal.openingDates')}
              </Label>
              <Controller
                control={control}
                name="openingDates"
                render={({ field }) => (
                  <DateRangePicker
                    startDate={field.value?.[0]?.startDate}
                    endDate={field.value?.[0]?.endDate}
                    onChange={(startDate, endDate) => {
                      field.onChange([{ startDate, endDate }]);
                    }}
                  />
                )}
              />
              {errors.openingDates && (
                <p className="text-red-500">{errors.openingDates?.message}</p>
              )}
            </div>

            {/* Public Checkbox */}
            <div>
              <Label htmlFor="public">{t('locationModal.public')}</Label>
              <div className="mt-1">
                <Checkbox id="public" {...register('public')} />
              </div>
              {errors.public && (
                <p className="text-red-500">{errors.public?.message}</p>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-t border-gray-200 !p-6 dark:border-gray-700">
          <Button type="submit">{t('locationModal.saveButton')}</Button>
          <Button color="gray" onClick={onClose}>
            {t('locationModal.cancelButton')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditLocationModal;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { CustomProductsApi, UpdateProduct } from 'cacao-sdk-typescript';

const editProduct = async (productId: string, updatedData: UpdateProduct) => {
  try {
    const productApi = new CustomProductsApi(axiosInstance);
    const { data } = await productApi.productsProductIdPatch(
      productId,
      updatedData
    );

    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error editing product:', error);
    throw new Error('Failed to edit product');
  }
};

export const useEditProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      productId,
      updatedData,
    }: {
      productId: string;
      updatedData: UpdateProduct;
    }) => editProduct(productId, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(['products'] as any);
    },
    onError: (error) => {
      Sentry.captureException(error);
      console.error('Error during mutation:', error);
    },
  });
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import { CustomContributorsApi, CreateContributor } from 'cacao-sdk-typescript';

const addContributor = async (contributorToCreate: CreateContributor) => {
  try {
    const contributorId = uuidv4();

    const contributorsApi = new CustomContributorsApi(axiosInstance);
    const { data } = await contributorsApi.contributorProposalContributorIdPut(
      contributorId,
      contributorToCreate
    );

    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error adding contributor:', error);
    throw new Error('Failed to add contributor');
  }
};

export const useAddContributor = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      contributorToCreate,
    }: {
      contributorToCreate: CreateContributor;
    }) => addContributor(contributorToCreate),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['contributors'],
      });
    },
    onError: (error) => {
      Sentry.captureException(error);
      console.error('Error during mutation:', error);
    },
  });
};

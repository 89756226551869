import { format } from 'date-fns';
import { Datepicker } from 'flowbite-react';

export interface DateRangePickerProps {
  startDate?: string;
  endDate?: string;
  onChange: (startDate?: string, endDate?: string) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onChange,
}) => {
  const handleStartChange = (date: Date) => {
    onChange(date.toISOString(), endDate);
  };

  const handleEndChange = (date: Date) => {
    onChange(startDate, date.toISOString());
  };

  return (
    <div
      id="date-range-picker"
      className="flex items-center gap-4 flex-wrap md:flex-nowrap"
    >
      <div className="w-full">
        <Datepicker
          id="start-date"
          type="date"
          value={startDate ? format(startDate, 'yyyy-MM-dd') : undefined}
          onSelectedDateChanged={handleStartChange}
          maxDate={endDate ? new Date(endDate) : undefined}
        />
      </div>
      <span className="text-gray-500">to</span>
      <div className="w-full">
        <Datepicker
          id="end-date"
          type="date"
          value={endDate ? format(endDate, 'yyyy-MM-dd') : undefined}
          onSelectedDateChanged={handleEndChange}
          minDate={startDate ? new Date(startDate) : undefined}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;

import React, { useState } from 'react';

export interface ObjectInputProps {
  keys?: string[];
  value: Record<string, string>;
  onChange: (value: Record<string, string>) => void;
  placeholder?: string;
}

const ObjectInput: React.FC<ObjectInputProps> = ({
  keys,
  value,
  onChange,
  placeholder,
}) => {
  const [selectedKey, setSelectedKey] = useState<string>(
    (keys ? keys[0] : 'monday') as string
  );
  const [currentValue, setCurrentValue] = useState<string>(
    value[selectedKey] || ''
  );

  const handleKeyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const key = e.target.value;
    setSelectedKey(key);
    setCurrentValue(value[key] || '');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setCurrentValue(input);

    const updatedValue = {
      ...value,
      [selectedKey]: input,
    };

    onChange(updatedValue);
  };

  return (
    <div className="">
      <div className="flex w-full bg-gray-50 border border-gray-300 rounded-lg overflow-hidden dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500">
        <select
          value={selectedKey}
          onChange={handleKeyChange}
          className="w-full h-full max-w-[40%] border-0 border-r border-gray-300 bg-transparent text-sm !outline-0 focus:border-gray-300"
        >
          {keys &&
            keys.map((key) => (
              <option key={key} value={key}>
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </option>
            ))}
        </select>
        <input
          type="text"
          placeholder={placeholder}
          value={currentValue}
          onChange={handleInputChange}
          className="w-full bg-transparent border-none text-sm !outline-0 focus:border-gray-300"
        />
      </div>
    </div>
  );
};

export default ObjectInput;
